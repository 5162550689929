import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import { NavLink, withRouter } from "react-router-dom";
import {
  Button,
  Col,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import { CSVLink } from "react-csv";
import {
  filterTalukas,
  filterWards,
  filterOption,
  filterCities,
  filterSocieties,
} from "../../common/helper";
import "./index.less";
import {
  CheckSquareFilled,
  DeleteOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import moment from "moment-timezone";
import { IsJsonString } from "../../common/utils";
import { badgeGenerator } from "../../common/helper";
import { notifyError, notifySuccess } from "../../common/notification";

const USER_ROLES = [
  { value: "all", label: "All" },
  { value: "core_committee", label: "Core committee" },
  { value: "convener", label: "Convener" },
  { value: "sub_convener", label: "Sub convener" },
];

const CoreMembersListPage = (props) => {
  const { core_members, districts, talukas, cities, wards, societies } = props;

  const [searchText, setSearchText] = useState("");

  const [selectedUserRole, setSelectedUserRole] = useState(null);
  const [districtId, setDistrictId] = useState(null);
  const [talukaId, setTalukaId] = useState(null);
  const [cityId, setCityId] = useState(null);
  const [wardId, setWardId] = useState(null);
  const [societyId, setSocietyId] = useState(null);

  const [filteredTalukas, setFilteredTalukas] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);
  const [filteredWards, setFilteredWards] = useState([]);
  const [filteredSocieties, setFilteredSocieties] = useState([]);

  const handleUserAction = async (id, action) => {
    const result = await props.updateCoreMemberStatus(id, { status: action });
    const { status, message } = result?.data;
    if (status) {
      notifySuccess(message);
      fetchData();
      return;
    }

    notifyError(message);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "sr_no",
      key: "sr_no",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <span>
          {record?.surname} {record?.first_name} {record?.last_name}
        </span>
      ),
    },
    {
      title: "Mobile",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "BG",
      dataIndex: "blood_group",
      key: "blood_group",
      render: (text, record) => (
        <>
          {record?.blood_group}{" "}
          {!!record?.interest_in_blood_donation && <CheckSquareFilled />}
        </>
      ),
    },
    {
      title: "Role",
      dataIndex: "user_role",
      key: "user_role",
      render: (text, record) => {
        const { user_role_access } = record || {};
        if (!IsJsonString(user_role_access)) return null;
        const userAccess = JSON.parse(record.user_role_access);
        const { role_name } = userAccess;
        return <>{badgeGenerator(role_name)}</>;
      },
      sorter: (a, b) => {
        const { user_role_access: userRoleAccessA } = a || {};
        const { user_role_access: userRoleAccessB } = b || {};
        if (!IsJsonString(userRoleAccessA)) return -1;
        if (!IsJsonString(userRoleAccessB)) return 1;
        const userAccessA = JSON.parse(a.user_role_access);
        const userAccessB = JSON.parse(b.user_role_access);
        const { role_name: roleA } = userAccessA;
        const { role_name: roleB } = userAccessB;
        return roleA.localeCompare(roleB);
      },
    },
    {
      title: "Last Login",
      dataIndex: "last_access_log",
      key: "last_access_log",
      render: (text, record) => (
        <>
          {record?.last_access_log &&
            moment(record?.last_access_log).local().format("DD-MM-YYYY")}
        </>
      ),
      sorter: (a, b) => {
        // there is no last_access_log in some records
        if (!a.last_access_log) return -1;
        if (!b.last_access_log) return 1;
        return (
          moment(a.last_access_log).unix() - moment(b.last_access_log).unix()
        );
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (text, record) => <>{record?.status}</>,
    },
    {
      title: "Action",
      key: "action",
      render: (_text, record) => {
        const { user_id, status } = record;
        const nextAction = "delete";
        return (
          <div className="btn-actions">
            <Space size="middle">
              <NavLink to={`/admin/core-members/${user_id}`}>Edit</NavLink>
              <Popconfirm
                title={`Are you sure to ${nextAction} this user?`}
                onConfirm={() => {
                  handleUserAction(user_id, nextAction);
                }}
              >
                <Button
                  type="primary"
                  size="small"
                  danger={status === "active"}
                >
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </Space>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    props.listDistrict();
    props.listTaluka();
    props.listCity();
    props.listWard();
    props.listSociety();

    fetchData();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedUserRole) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [selectedUserRole]);

  useEffect(() => {
    if (districtId) {
      setFilteredTalukas(filterTalukas(districtId, talukas));
    }
    // eslint-disable-next-line
  }, [districtId]);

  useEffect(() => {
    if (talukaId) {
      setFilteredCities(filterCities(talukaId, cities));
    }
    // eslint-disable-next-line
  }, [talukaId]);

  useEffect(() => {
    if (cityId) {
      setFilteredWards(filterWards(cityId, wards));
    }
    // eslint-disable-next-line
  }, [cityId]);

  useEffect(() => {
    if (wardId) {
      setFilteredSocieties(filterSocieties(wardId, societies));
    }
    // eslint-disable-next-line
  }, [wardId]);

  const handleFilter = () => {
    fetchData();
  };

  const fetchData = () => {
    const params = {};
    if (districtId) params.district_id = districtId;
    if (talukaId) params.taluka_id = talukaId;
    if (cityId) params.city_id = cityId;
    if (wardId) params.ward_id = wardId;
    if (societyId) params.society_id = societyId;
    if (selectedUserRole && selectedUserRole !== "all")
      params.user_role = selectedUserRole;
    if (searchText) params.keyword = searchText;

    props.listCoreMember(params);
  };

  let results = [];

  if (core_members?.length > 0) {
    results = core_members.map((row, index) => {
      const obj = {
        sr_no: index + 1,
        key: row.user_id,
        ...row,
      };
      delete obj["_admin_id"];
      delete obj["u_password"];
      delete obj["user_image"];
      delete obj["district_id"];
      delete obj["taluka_id"];
      delete obj["city_id"];
      delete obj["ward_id"];
      delete obj["is_deleted"];
      return obj;
    });
  }

  const exportTimeStamp = moment().format("YYYY-MM-DD-HH:mm:ss");

  return (
    <>
      <Row gutter={[10, 20]} className="filter-row">
        <Col>
          <h4>Internal Members</h4>
        </Col>
        <Col>
          <Space>
            {results?.length > 0 && (
              <CSVLink
                data={results}
                filename={`kdvs-core-member-${exportTimeStamp}.csv`}
              >
                <Button type="secondary">
                  <DownloadOutlined />
                  Download CSV
                </Button>
              </CSVLink>
            )}
            <NavLink to="/admin/core-members/create">
              <Button type="primary">+ New Internal member</Button>
            </NavLink>
          </Space>
        </Col>
      </Row>

      <Row gutter={[10, 20]} className="filter-row">
        <Col>
          <Input
            defaultValue={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search"
          />
        </Col>
        <Col flex={1}>
          <div className="filter-menu">
            <Select
              placeholder="Select District"
              showSearch
              filterOption={filterOption}
              onChange={(value) => setDistrictId(value)}
            >
              {districts?.map((item, index) => (
                <Select.Option key={index} value={item.district_id}>
                  {item.district_name}
                </Select.Option>
              ))}
            </Select>

            <Select
              placeholder="Select Taluka"
              showSearch
              filterOption={filterOption}
              onChange={(value) => setTalukaId(value)}
            >
              {filteredTalukas?.map((item, index) => (
                <Select.Option key={index} value={item.taluka_id}>
                  {item.taluka_name}
                </Select.Option>
              ))}
            </Select>

            <Select
              placeholder="Select City"
              showSearch
              filterOption={filterOption}
              onChange={(value) => setCityId(value)}
            >
              {filteredCities?.map((item, index) => (
                <Select.Option key={index} value={item.city_id}>
                  {item.city_name}
                </Select.Option>
              ))}
            </Select>

            <Select
              placeholder="Select Ward"
              showSearch
              filterOption={filterOption}
              onChange={(value) => setWardId(value)}
            >
              {filteredWards?.map((item, index) => (
                <Select.Option key={index} value={item.ward_id}>
                  {item.ward_name}
                </Select.Option>
              ))}
            </Select>

            <Select
              placeholder="Select Society"
              showSearch
              filterOption={filterOption}
              onChange={(value) => setSocietyId(value)}
            >
              {filteredSocieties?.map((item, index) => (
                <Select.Option key={index} value={item.society_id}>
                  {item.society_name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col>
          <Button type="secondary" onClick={handleFilter}>
            Search
          </Button>
        </Col>
      </Row>
      <Row gutter={[10, 20]} className="filter-row">
        <Col span={6}>
          <div className="filter-menu">
            <Select
              placeholder="Select User Role"
              showSearch
              filterOption={filterOption}
              onChange={(value) => setSelectedUserRole(value)}
            >
              {USER_ROLES?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Col>
      </Row>
      <Table
        dataSource={results}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => {
            const { user_role_access } = record || {};
            if (!IsJsonString(user_role_access)) return null;
            const userAccess = JSON.parse(record.user_role_access);
            const {
              role_name,
              is_all_access,
              is_specific_district_access,
              is_specific_taluka_access,
              is_specific_city_access,
              is_specific_ward_access,
              is_specific_society_access,
              district_name,
              taluka_name,
              city_name,
              ward_name,
              society_name,
            } = userAccess;
            return (
              <>
                {!!role_name && <p>Role: {role_name}</p>}
                {!!is_all_access && (
                  <p>
                    All Access: <CheckSquareFilled />
                  </p>
                )}
                {!!is_specific_district_access && (
                  <p>
                    District: <CheckSquareFilled /> {district_name || ""}
                  </p>
                )}
                {!!is_specific_taluka_access && (
                  <p>
                    Taluka: <CheckSquareFilled /> {taluka_name || ""}
                  </p>
                )}
                {!!is_specific_city_access && (
                  <p>
                    City: <CheckSquareFilled /> {city_name || ""}
                  </p>
                )}
                {!!is_specific_ward_access && (
                  <span>
                    Ward: <CheckSquareFilled /> {ward_name || ""}
                  </span>
                )}
                {!!is_specific_society_access && (
                  <p>
                    Society: <CheckSquareFilled /> {society_name || ""}
                  </p>
                )}
              </>
            );
          },
        }}
        pagination={{
          position: "bottomRight",
          pageSize: 10,
          hideOnSinglePage: true,
        }}
      />
    </>
  );
};

export default connect(
  (state) => ({
    districts: state.districts,
    talukas: state.talukas,
    cities: state.cities,
    wards: state.wards,
    societies: state.societies,
    core_members: state.core_members,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(CoreMembersListPage));
